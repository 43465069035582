@import '../../Assets/Styles/variables.scss';

.riaMainWrapper {
    .page-wrapper {
        .aboutBanner {
            background: url(../../Assets/Images/ria-about-bg.jpg) no-repeat center center;
            background-size: cover;
            &::before {
                background: transparent;
            }
            .container {
                height: auto;
                padding: 50px 20px 30px 20px !important;
                position: relative;
                z-index: 2;
                h1 {
                    font-style:italic;
                    font-size: 30px !important;
                }
                h1, p {
                    text-shadow: 1px 1px 2px $black100;
                    color: $white100 !important;
                }
                p {
                    font-size: 16px;
                }
                .count-section {
                    background: transparent !important;
                    h4, p, i {
                        text-shadow: 1px 1px 2px $black100;
                        color: $white100 !important;
                    }
                    .bi {
                        font-size: 24px !important;
                    }
                }
            }
        }
        .about-section {
            h3 {
                color: $riathemecolordark;
                font-weight: 500;
                i {
                    color: $riathemecolordark;
                }
            }
        }
        // #joinUs {
        //     background-image: url(../../Assets/Images/jon-us-bg.jpg);
        //     &::before {
        //         background: linear-gradient(90deg, rgb(23 135 80 / 71%) 0%, rgb(27 155 89 / 65%) 100%);
        //     }
        //     .book-appointment-box {
        //         h3 {
        //             color: $riathemecolordark;
        //             font-weight: 400 !important;
        //         }
        //     }
        // }
    }
}
