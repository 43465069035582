@import "./variables.scss";
@import "./mixins.scss";


.main-header {
    display: flex;
    background: $white100;
    z-index: 9999;
    width: 100%;
    .navbar-brand {
        color: var(--primary100); 
        padding: 2px 0;
        &:hover,
        &:focus {
            color: var(--primary100)
        }
    }
    .welcometext{
        font-size: 14px!important;
    }
    .btn {
        padding: 8px 16px;
    }
    .navbar {
        padding: 0;
        .navbar-nav {
            margin: 0;
            .role-dropdown-wrapper {
                .nav-item {                 
                    #role-nav-dropdown {
                        padding: 0 12px 0 0 !important;
                    }
                    &::after {
                        content: "\f229";
                        position: absolute;
                        top: 3px;
                        right: 0;
                        z-index: -1;
                        font-family: bootstrap-icons !important;
                        font-size: 10px;
                    }      
                }
            }
            .nav-link {
                color: $black100;
                padding: 15px 12px !important;
                line-height: normal;
                font-weight: 500;
                span {
                    border-bottom: 1px solid $transparent !important;
                    padding: 3px 0;
                }
                &.active {
                    span {
                        border-bottom: 1px solid $black100 !important;
                        font-weight: 500;
                    }
                }
                &.active,
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $black100 !important;
                    span {
                        border-bottom: 1px solid $black100 !important;
                    }
                }
                &.active {
                    span {
                        font-weight: 500;
                    }
                }
                &#nav-dropdown-profile {
                    padding: 0 10px !important;
                }
                &#role-nav-dropdown {
                    font-size: 12px;
                    padding: 11px 12px 9px !important;
                }
                &.btn {
                    padding: 8px 16px !important;
                    font-weight: 500 !important;
                }
                &:last-child {
                    border: 0;
                }
                &.btn-logout {
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--primary50) !important;
                    }
                }
            } 
            &.regLogin {
                background: var(--primary100) !important;
                color: $white100 !important;
                border-color: var(--primary100) !important;
                padding: 0 10px !important;
                height: 26px !important;
                .nav-link {
                    padding: 2px !important;
                    color: $white100 !important;
                    &:hover,
                    &:focus {
                        color: $white100 !important;
                    }
                }
                &:hover,
                &:focus {
                    border-color: var(--primary100) !important;
                }
            } 
            .nav-item {
                .nav-link {
                    span {
                        border-bottom: 1px solid $transparent !important;
                        padding: 3px 0;
                       
                    }
                    &.active {
                        color: #000000!important;                        
                        span {
                            border-bottom: 1px solid var(--primary100) !important;
                            border-bottom: 1px solid #000000 !important;
                            font-weight: 500;
                        }
                    }
                    
                    &:hover {
                        color: #000000!important;
                        span {
                            border-bottom: 1px solid var(--primary100) !important;
                            border-bottom: 1px solid #000000 !important;
                        }
                    }
                }
                &.active {
                    .nav-link {
                        color: var(--primary100);
                        span {
                            border-color: var(--primary100) !important;
                        }
                    }
                }
            }      
        }
        .menuUser {
            position: relative;
            #nav-dropdown-profile {
                padding: 7px 0 !important;
                i {
                    font-size: 26px;
                    &::before {
                        color: $black100;
                    }
                }
            }
            .dropdown-item {
                &.active {
                    background-color: $white100;
                }
            }
        }
        .dropdown {
            .dropdown-menu {
                top: 52px;
                right: auto;
                left: 0px;
            }
            .dropdown-item {
                font-size: 14px;
                border: 0;
                .dropdown-item {
                    padding: 0 !important;
                    i {
                        line-height: 10px;
                    }
                }
                &:hover,
                &:focus,
                &:active {
                    background-color: var(--primary50);
                    color: $white100!important;
                    a {
                        &:hover,
                        &:focus,
                        &:active {
                            color: $white100;
                        }
                    }
                    .dropdown-item {
                        color: $white100 !important;
                    }
                }
                i {
                    font-size: 16px;
                    position: relative;
                    top: 1px;                }
            }
        }
        .role-switch-dp,
        .menuUser {
            .dropdown-menu {
                transform: none !important;
                inset: 0px 0px auto auto !important;
                right: 0 !important;
                top: 100% !important;
            }
        }
        .navbar-collapse {
            .role-switch-dp {
                .singleRole  {
                    font-size: 12px;
                    text-transform: capitalize;
                }
                .headerCustName {
                    color: $black100;
                    margin-top: 13px;
                    font-size: 14px !important;
                    font-weight: 600;
                    padding-top: 2px;
                }
                .singleRoleWrspper {
                    .singleRole {
                        padding: 0 !important;
                    }
                }
            }   
        }
        .navbar-toggler {
            color: $white100;
            border-color: $white100;
            border: none;
            padding: 6px 10px;
            .bi {
                font-size: 26px;
                //color: var(--primary50);
            }
        }
    }
}    
.mainTopHeader {
    padding: 0px 20px !important;
    min-height: 50px !important;
    box-shadow: 0px 1px 4px 0px #0000001A;
    .logo-mini {
        height: 50px;
        width: 50px !important;
        img {
            width: 100%;
        }
    }
    .sidebar-toggle {
        color: $white100;
        text-decoration: none;
    }
    ul {
        li {
            // float: left;
            position: relative;
        }
    }
    .dropdown {
        border: none;
        top: 0;
        z-index: 9;
        .dropdown-toggle,
        .dropdown-toggle:focus,
        .dropdown-toggle:hover,
        .dropdown-toggle:focus-visible {
            border: none !important;
            background: none;
            //color: $white100;
            //font-size: 13px;
            //font-weight: 800;
            &::after {
                display: none;
            }
        }
        &.nav-item {
            font-size: 14px;
            font-weight: 500;
            .dropdown-toggle .bi{
                font-size: 22px;
            }
            a {
                font-weight: 500!important;
                font-size: 14px;
                //color: $white100!important;
                padding: 15px 12px !important;
                line-height: normal;
                text-decoration: none;
                display: block;
                &:hover,
                &:focus {
                    // color: var(--primary100);
                    color: #000000;
                }
            }
            .dropdown-menu {
                font-weight: 400;
                font-size: 14px;
                // box-shadow: none!important;
                overflow: hidden;
                top: 51px;
                border-radius: 2px;
                background: var(--primary15);
                &.show {
                    display: none;
                }
                a {
                    font-size: 12px;
                    padding: 4px 10px !important;
                    color: $black100!important;
                    margin: 0;
                    &:hover,
                    &:focus,
                    &.active {
                        background-color: var(--primary50);
                        color: $white100 !important;
                    }
                    div{
                        font-size: 11px;
                    }
                }
                li {
                    &.active {
                        a {
                            // background-color: var(--primary50);
                            // color: $white100 !important;
                            &:hover,
                            &:focus {
                                background-color: var(--primary50);
                                color: $white100 !important;
                            }
                        }
                    }
                }
            }
        }
        li {
            float: none;
            a {
                &:hover,
                &:focus {
                    color: var(--primary50);
                    background-color: $transparent;
                }
            }
        }
    }
}
.userprofile-menu {
    .profile-name {
        color: var(--primary100);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        h6 {
            color: $black100;
            margin-top: 2px;
            font-size: 12px !important;
            font-weight: 500;
            margin-bottom: 0px;
        }
        .dropdown.nav-item a {
            padding: 0px!important;
        }
        .dropdown {
            display: inline;
            .btn {
                height: auto !important;
                font-size: 12px !important;
                font-weight: 400 !important;
                color: #0186FF !important;
            }
        }
    }
    .menu-profile {
        font-size: 14px;
        font-weight: 400;
        .profile-img {
            padding: 0;
            overflow: hidden;
            width: 35px;
            height: 35px;
            border: 2px solid #ddd;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.headerRightSec {
    .dropdown-menu li a{
        background: $white100!important;
        color: #444;
    }
    padding: 2px 15px 0 0;
    padding: 0px 30px 0 0;
    ul {
        .bi {
            font-size: 14px;
            line-height: 10px;
            &.caretIcon {
                font-size: 8px;
            }
        }
        li {
            .countH {
                background: $red100;
                display: inline-block;
                position: absolute;
                right: 8px;
                top: 0;
                z-index: 9;
                height: 12px;
                width: 12px;
                color: $white100;
                font-size: 7px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;  
            }
            a {
                font-weight: 400;
                font-size: 11px;
                padding: 0 !important;
                border: 0;
                // color: $black100!important;
                &:hover,
                &:focus {
                    color: $green5;
                }
            }
            ul {
                li {
                    a {
                        padding: 4px 10px !important;
                    }
                    &.regLogin {
                        padding: 0 10px !important;
                        background: var(--primary100) !important;
                        color: $white100 !important;
                        border-color: var(--primary100) !important;
                        height: 26px !important;
                        a {
                            color: $white100;
                            padding: 0 2px !important;
                        }
                        &:hover,
                        &:focus {
                            border-color: var(--primary100) !important;
                        }
                    }
                    .btn.btn-xs {
                        padding: 3px 5px 5px !important;
                        height: auto !important;
                        font-size: 12px !important;
                        a.btnLink:link, a.btnLink:visited{
                          color: $white100!important;
                          text-decoration: none;
                          font-size: 12px !important;
                        }
                    }
                }
            }
        }
    }
    .dropdown {
        .dropdown-menu {
            &.show {
                display: none;
            }
        }
    }
}

.dropdown-menu {
    // box-shadow: 0px 0px 5px 1px #dbdbdb !important;
    border-color: $white50;
    li {
        a {
            //background: #f4f4f4;
            color: $black100;
            &:hover {
                background: var(--primary50) !important;
                color: $white100 !important;
                .bi {
                    color: $white100;
                }
            }
            .bi {
                margin: 0 6px;
                color: $black100;
                font-size: 16px; 
            }
        }
    }
    li > a
    .divider {
        background-color: $white100;
    }
}
.navbar-custom-menu {
    .notifications-menu,
    .messages-menu,
    .userprofile-menu {
        .dropdown-menu {
            min-width: 115px;
            padding: 0;
            margin: 0;
            top: 15px!important;
            li {
                position: relative;
                margin: 0 !important;
                width: 100%;
                &.header {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 7px 10px;
                    border-bottom: 1px solid $white100;
                    color: #444444;
                    background: #f4f4f4;
                    font-size: 14px;
                }
                &.footer {
                    a {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                        padding: 7px 10px;
                        border-top: 1px solid $white100;
                        color: #444;
                        background: #f4f4f4;
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .menu {
                    max-height: 230px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    overflow-x: hidden;
                    li {
                        a {
                            display: block;
                            white-space: nowrap;
                            //border-bottom: 1px solid #f4f4f4;
                            color: $black100;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding: 8px 10px;
                            font-size: 12px;
                            line-height: normal;
                            &:hover {                                
                                text-decoration: none;
                                color: $white100;
                                .bi {
                                    color: $white100;
                                }
                            }
                            .bi {
                                font-size: 14px;
                                vertical-align: middle;
                                &::before {
                                    vertical-align: top;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .notifications-menu,
    .userprofile-menu,
    .role-switch-dp,
    .menuUser {
        .dropdown-menu {
            // top: 40px !important;
            // right: 5px !important;
            overflow: hidden;
            border-radius: 4px;
            //transform: translateY(0px) !important;
            transform: none !important;
            inset: 0px 0px auto auto !important;
            right: 0 !important;
            top: 100% !important;
            font-size: 14px;
        }
        .investor-dp {
            .dropdown-menu {
                //top: 12px !important;
                li {
                    a {
                        color: #444;
                        padding: 8px 10px;
                        font-size: 12px;
                        line-height: normal;
                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .messages-menu {
        .dropdown-menu {
            li {
                .menu {
                    li {
                        a {
                            margin: 0;
                            padding: 10px;
                            border-top-right-radius: 0;
                            border-top-left-radius: 0;
                            border-top-width: 0;
                            width: 280px;
                            &:before,
                            &:after {
                                content: " ";
                                display: table;
                            }
                            &:after {
                                clear: both;
                            }
                            img {
                                margin: auto 10px auto auto;
                                width: 40px;
                                height: 40px;
                                filter: grayscale(1);
                            }
                            h4 {
                                padding: 0 55px 0 0;
                                margin: 0 0 0 45px;
                                color: #444444;
                                font-size: 15px;
                                position: relative;
                                small {
                                    color: #999999;
                                    font-size: 10px;
                                    position: absolute;
                                    top: 0;
                                    right: 15px;
                                }
                            }
                            h4, p {
                                margin: 0 0 0 45px;
                                font-size: 12px;
                                color: #888888;
                            }
                            h3 {
                                font-size: 14px;
                                padding: 0;
                                margin: 0 0 10px 0;
                                color: #666666;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .navbar-custom-menu  {
        .notifications-menu {
            .dropdown-menu {
                li {
                    &.footer {
                        a {
                            background: $white100 !important;
                            color: #444 !important;
                            &:hover {
                                text-decoration: none;
                                font-weight: normal;
                            }
                            .bi {
                                width: 20px;
                            }
                        }
                    }
                }
            }
        } 
    }
}

@media (max-width: 768px) {
    .headerRightSec {
        padding-right: 15px;
    }
    .mainWrapper{
       
        .navbar .navbar-toggler{
            color: var(--primary100)!important;
        }
        .mainWrapper .mainTopHeader .dropdown.nav-item a{
            color: $black100!important;
        }
    }
    
    .navbar .navbar-toggler{
        color: var(--primary100)!important;
    }
    .main-header {
        .navbar .navbar-nav .nav-link{
            color: $black100 !important; 
        }
        .welcometext{
            display: none;
        }
        .dropdown.nav-item a{
            color: $black100 !important;
        }
    }
}
.navbar-nav .nav-link{
    //color: $white100;
}
.navbar-nav .nav-link.active{
    //color: $white100;
}

.mainTopHeader li.dropdown:hover ul.dropdown-menu{ display: block;}
.mainTopHeader .dropdown li {
    float: none;
}
.main-header {
    &.riaHeader {
        .navbar {
            .dropdown {
                .dropdown-item i {
                    top: 2px;
                }
            }
        }
    }
}
.loginLinks{
    
    .btnLink{
        //color: var(--primary100) !important;
        text-decoration: none;
        font-size: 14px!important;
    }
    .btnLink:hover{
        color: var(--primary100)!important;
        //text-decoration: underline;
    }
}

.mainWrapper .main-header.mainTopHeader {
    .navbar .navbar-nav .nav-link,


    .dropdown.nav-item, .dropdown.nav-item a {
        font-weight: 100!important;
    }

    .dropdown {
       
       
            a {
                font-weight: 100!important;
            }
        }
}  

.mfdHome, .riaHome, .raHome{
    .main-header .navbar .navbar-nav .nav-link.active span {
    border: none!important;
    }
    .userprofile-menu .profile-name{
        h6, .text-primary {
            color: #ffffff!important;
        }
    } 
}

.logobox {
    min-height: 35px;
}