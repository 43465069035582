// Navy Blue Theme
$gradient: linear-gradient(90deg, #163746 0%, #1d465a 99.99%, #fff 100%);
$primary05: #0765D40D;
$primary15: #F6F9FB;
$primary25: #F0F5FD;
$primary35: #f4f9fc;
$primary50: #0495dd;
$primary75: #048cd0;
$primary100: #01679a;
$primary200: #01679a;

$riathemecolor: #056B44;
$riathemecolorlight: #EDFDF7;
$riathemecolordark: #013B25;
$riathemecolorgreen: #4DAC03;

$secondary10: #eff8fd;
$secondary25: #daf3ff;
$secondary50: #81a6df;
$secondary75: #048cd0;
$secondary100: #007EBD;
$secondary200: #006ba1;
$secondary300: #005a87;

$grey15:rgba(153, 160, 168, 0.15);
$grey25:rgba(153, 160, 168, 0.25);
$grey75: #3a454f;
$grey50:rgba(153, 160, 168, 0.5);
$grey100:#9AA3A1;
$grey200:#72777d;
$grey300:#494c4f;
$grey400:#DDE2E5;
$grey500: #505B5A;
$grey900: #9AA3A1;
$grey600: #dbdfe2;
$grey700: #BCBCBC;
$grey0: #d9d5ec;

$red25: rgba(240, 61, 62, 0.1);
$red50:rgba(255, 0, 0, 0.5);
$red75:#d34747;
$red100:#E93535;
$red200:#ab0202;

$orange50:rgba(255, 89, 0,0.5);
$orange100:#ff9800;
$orange200:#ab3d02;

$green5: #8BC34A;
$green25:rgba(123, 194, 104, 0.25);
$green35: #d5edc7;
$green50:rgba(104, 165, 87,0.5);
$green75: #8BC34A;
$green100:#68A557;
$green200:#426937;
$green300:#E5F1D4;

$yellow100: #FFC400;

$black25:rgba(0, 0, 0, 0.4);
$black50:rgba(0, 0, 0, 0.25);
$black75:#1c1c1c;
$black100:#000;
$black200: #403401;
$black300: #343434;
$black400: #252B42;

$white25:#E9E9E9;
$white75: #F3F5F6;
$white50:#F8F9FA;
$white100:#ffffff;
$white125: #FAFAFA;
$white200: #f8f9fa;
$white300: #fafafa;

$transparent: transparent;

$font-family-poppins: 'Poppins', sans-serif;
$font-family-roboto:'Roboto', sans-serif !important;
$font-family-bootstrap-icons: bootstrap-icons !important;

// Text Shadow
$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

$purple: #1e4aa8;
$blue: #007EBD;
$golden : #DBB801;
$golden2 :rgb(142, 119, 2);
$maroon:#99025D;
$green :#039C65;

//New Set for Custom Theming

$primary15: rgb(232, 247, 255);
$primary25: rgb(177, 226, 250);
$primary50: rgb(67, 167, 216);
$primary100: rgb(8, 123, 180); // Primary
$primary200: rgba(0, 103, 154);  //#01679a; Original set Blue


$red15: rgb(255, 227, 227);
$red25: rgb(243, 181, 181);
$red50: rgb(237, 110, 110);
$red100: rgb(234, 29, 29); // Primary
$red200: rgb(204, 13, 13);  //#01679a; Original set Red
 
$orange15: rgb(251, 222, 206);
$orange25: rgb(232, 179, 151);
$orange50: rgb(233, 136, 84);
$orange100: rgba(233, 95, 21); // Primary
$orange200: rgb(212, 84, 15);  //#01679a; Original set Orange

$yellow15: rgb(254, 246, 222);
$yellow25: rgb(253, 236, 186);
$yellow50: rgb(253, 213, 91);
$yellow100: rgba(255, 193, 7); // Primary
$yellow200: rgb(211, 159, 3);  //#01679a; Original set Yellow
 
$green15: rgb(226, 255, 243);
$green25: rgb(170, 234, 208);
$green50: rgb(53, 185, 132);
$green100: rgba(0, 154, 92); // Primary
$green200: rgb(1, 110, 66);  //#01679a; Original set Green
 
$blue15: rgb(221, 236, 255);
$blue25: rgb(182, 212, 252);
$blue50: rgb(94, 162, 250);
$blue100: rgba(0, 110, 254); // Primary
$blue200: rgb(2, 78, 176);  //#01679a; Original set Blue
 
$maroon15: rgb(255, 227, 241);
$maroon25: rgb(225, 166, 196);
$maroon50: rgb(188, 70, 129);
$maroon100: rgba(154, 0, 77); // Primary
$maroon200: rgb(111, 2, 57);  //#01679a; Original set Maroon
 
 
//  $primary25: rgba(255, 0, 174, 0.25);
//  $primary50: rgba(255, 0, 174, 0.5);
//  $primary100: rgba(255, 0, 174, 0.9); // Primary
//  $primary200: rgb(255, 0, 174);  //#01679a; Original set Pink
 
 
:root{
    --primary100: #{$primary100};
    --primary200: #{$primary200};
    --primary50: #{$primary50};
    --primary25: #{$primary25};
    --primary15: #{$primary15};
}

:root .defaultTheme {
    --primary100: #{$primary100};
    --primary200: #{$primary200};
    --primary50: #{$primary50};
    --primary25: #{$primary25};
    --primary15: #{$primary15};
}

:root .redTheme {
        --primary100: #{$red100};
        --primary200: #{$red200};
        --primary50: #{$red50};
        --primary25: #{$red25};
        --primary15: #{$red15};
    }

:root .orangeTheme {
        --primary100: #{$orange100};
        --primary200: #{$orange200};
        --primary50: #{$orange50};
        --primary25: #{$orange25};
        --primary15: #{$orange15};
    }

:root .yellowTheme {
        --primary100: #{$yellow100};
        --primary200: #{$yellow200};
        --primary50: #{$yellow50};
        --primary25: #{$yellow25};
        --primary15: #{$yellow15};
    }

:root .greenTheme {
        --primary100: #{$green100};
        --primary200: #{$green200};
        --primary50: #{$green50};
        --primary25: #{$green25};
        --primary15: #{$green15};
    }

:root .blueTheme {
        --primary100: #{$blue100};
        --primary200: #{$blue200};
        --primary50: #{$blue50};
        --primary25: #{$blue25};
        --primary15: #{$blue15};
    }

:root .maroonTheme {
        --primary100: #{$maroon100};
        --primary200: #{$maroon200};
        --primary50: #{$maroon50};
        --primary25: #{$maroon25};
        --primary15: #{$maroon15};
    }

    :root .redTheme .mfdHome,
    :root .orangeTheme .mfdHome,
    :root .yellowTheme .mfdHome,
    :root .greenTheme .mfdHome,
    :root .blueTheme .mfdHome,
    :root .maroonTheme .mfdHome,
    
    :root .redTheme .riaHome,
    :root .orangeTheme .riaHome,
    :root .yellowTheme .riaHome,
    :root .greenTheme .riaHome,
    :root .blueTheme .riaHome,
    :root .maroonTheme .riaHome,

    :root .redTheme .poHome,
    :root .orangeTheme .poHome,
    :root .yellowTheme .poHome, 
    :root .greenTheme .poHome,
    :root .blueTheme .poHome,
    :root .maroonTheme .poHome,

    :root .redTheme .raHome,
    :root .orangeTheme .raHome,
    :root .yellowTheme .raHome, 
    :root .greenTheme .raHome,
    :root .blueTheme .raHome,
    :root .maroonTheme .raHome 

    {
        --primary100: #{$primary100};
        --primary200: #{$primary200};
        --primary50: #{$primary50};
        --primary25: #{$primary25};
        --primary15: #{$primary15};
    }
    // body .redtheme {
    //     --primary100: $red100;
    //     --primary200: $red200;
    //     --primary50: $red50;
    //     --primary25: $red25;
    //     --primary15: $red15;
    //     }
   
    .text-primary{
        color: var(--primary200)!important;
    }
    :root .defaultTheme.darkMode{
        --primary200: #{$primary100};
    }
    :root .redTheme.darkMode{
        --primary200: #{$red100};
    }
    :root .orangeTheme.darkMode{
        --primary200: #{$orange100};
    }
    :root .yellowTheme.darkMode{
        --primary200: #{$yellow100};
    }
    :root .greenTheme.darkMode{
        --primary200: #{$green100};
    }
    :root .blueTheme.darkMode{
        --primary200: #{$blue100};
    }
    :root .maroonTheme.darkMode {
        --primary200: #{$maroon100};
    }
    
    :root .darkMode { // this is for calendar in dark mode
    color-scheme: dark;
    //--text: white;
    //--background: black;
    }