@import "./variables.scss";
@import "./mixins.scss";

html,
body {
    height: 100%;
    
}
body {
   font-family: $font-family-roboto;
   font-weight: 400;
   font-size: 14px;
   overflow-x: hidden;
   overflow-y: auto;
   color: $black100;
}
#root {
   position: relative;
   min-height: 100vh;
   background-color: $grey400;
}
.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
a {
    color: var(--primary100);
    &:hover,
    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
        color: var(--primary100);
    }
}
.page-header {
    margin: 10px 0 20px 0;
    font-size: 22px;
}
.page-header > small {
    color: #666;
    display: block;
    margin-top: 5px;
}
.main-header {
    //position: relative;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    max-height: 100px;
    z-index: 9999;
    .navbar {
        -webkit-transition: margin-left 0.3s ease-in-out;
        -o-transition: margin-left 0.3s ease-in-out;
        transition: margin-left 0.3s ease-in-out;
        margin-bottom: 0;
        border: none;
        min-height: 41px;
        border-radius: 0;
    }
}
.mainWrapper{
    .main-header {
        position: relative;
        box-shadow: none;
        .logo {
            font-size: 22px;
        }
    }
    
}
.layout-top-nav .main-header .navbar {
    margin-left: 0;
}
.main-header #navbar-search-input.form-control {
    background: rgba(255, 255, 255, 0.2);
    border-color: $transparent;
}
.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.9);
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
    color: #ccc;
    opacity: 1;
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
    color: #ccc;
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
    color: #ccc;
}
.main-header .navbar-custom-menu,
.main-header .navbar-right {
    float: right;
}
@media (max-width: 991px) {
    .main-header .navbar-custom-menu a,
    .main-header .navbar-right a {
        background: $transparent;
    }
}
@media (max-width: 767px) {
    .main-header .navbar-right {
        float: none;
    }
    .navbar-collapse .main-header .navbar-right {
        margin: 7.5px -15px;
    }
    .main-header .navbar-right > li {
        color: inherit;
        border: 0;
    }
}
.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
    margin-right: 5px;
}
.main-header .navbar .nav > li > a > .label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: 0.9;
}
.main-header {
    .logo {
        -webkit-transition: width 0.3s ease-in-out;
        -o-transition: width 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;
        display: block;
        float: left;
        //height: 50px;
        font-size: 18px;    
        //line-height: 50px;
        // text-align: center;
        font-family: $font-family-roboto;
        padding: 4px 0 3px 0;
        font-weight: 300;
        overflow: hidden;
        max-width: 270px;
        line-height: 18px;
        span {
            font-weight: 400;
            color: $black100;
        }
        .logo-mini {
            display: none;
        }
    }
    .navbar-brand {
        color: $black100;
    }
}
.content-header {
    position: relative;
    padding: 15px 15px 0 15px;
}
.content-header > h1 {
    margin: 0;
    font-size: 24px;
}
.content-header > h1 > small {
    font-size: 15px;
    display: inline-block;
    padding-left: 4px;
    font-weight: 300;
}
.navbar-toggle {
    color: $white100;
    border: 0;
    margin: 0;
    padding: 15px 15px;
}
@media (max-width: 991px) {
    .navbar-custom-menu .navbar-nav > li {
        float: left;
    }
    .navbar-custom-menu .navbar-nav {
        margin: 0;
        float: left;
    }
    .navbar-custom-menu .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 20px;
    }
}
@media (max-width: 767px) {
    .main-header {
        position: relative;
        max-height: none;
        .navbar {
            margin: 0;
        }
        .navbar-custom-menu {
            float: right;
        }
        // .logo {
        //     padding-right: 10px;
        // }
    }    
}
@media (max-width: 991px) {
    .navbar-collapse.pull-left {
        float: none !important;
    }
    .navbar-collapse.pull-left + .navbar-custom-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 40px;
    }
}
.user-panel {
    position: relative;
    width: 100%;
    padding: 10px;
    overflow: hidden;
}
.user-panel:before,
.user-panel:after {
    content: " ";
    display: table;
}
.user-panel:after {
    clear: both;
}
.user-panel > .image > img {
    width: 100%;
    max-width: 45px;
    height: auto;
}
.user-panel > .info {
    padding: 5px 5px 5px 15px;
    line-height: 1;
    position: absolute;
    left: 55px;
}
.user-panel > .info > p {
    font-weight: 600;
    margin-bottom: 9px;
}
.user-panel > .info > a {
    text-decoration: none;
    padding-right: 5px;
    margin-top: 3px;
    font-size: 11px;
}
.user-panel > .info > a > .fa,
.user-panel > .info > a > .ion,
.user-panel > .info > a > .glyphicon {
    margin-right: 3px;
}

.control-sidebar-bg {
    position: fixed;
    z-index: 1000;
    bottom: 0;
}
.control-sidebar-bg,
.control-sidebar {
    top: 0;
    right: -230px;
    width: 230px;
    -webkit-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
}
.control-sidebar {
    position: absolute;
    padding-top: 50px;
    z-index: 1010;
}
@media (max-width: 768px) {
    .control-sidebar {
        padding-top: 100px;
    }
}
.control-sidebar > .tab-content {
    padding: 10px 15px;
}
.control-sidebar.control-sidebar-open,
.control-sidebar.control-sidebar-open + .control-sidebar-bg {
    right: 0;
}
.control-sidebar-open .control-sidebar-bg,
.control-sidebar-open .control-sidebar {
    right: 0;
}
@media (min-width: 768px) {
    .control-sidebar-open .content-wrapper,
    .control-sidebar-open .right-side,
    .control-sidebar-open .main-footer {
        margin-right: 230px;
    }
}
.fixed .control-sidebar {
    position: fixed;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
}
.nav-tabs.control-sidebar-tabs > li:first-of-type > a,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:hover,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:focus {
    border-left-width: 0;
}
.nav-tabs.control-sidebar-tabs > li > a {
    border-radius: 0;
}
.nav-tabs.control-sidebar-tabs > li > a,
.nav-tabs.control-sidebar-tabs > li > a:hover {
    border-top: none;
    border-right: none;
    border-left: 1px solid $transparent;
    border-bottom: 1px solid $transparent;
}
.nav-tabs.control-sidebar-tabs > li > a .icon {
    font-size: 16px;
}
.nav-tabs.control-sidebar-tabs > li.active > a,
.nav-tabs.control-sidebar-tabs > li.active > a:hover,
.nav-tabs.control-sidebar-tabs > li.active > a:focus,
.nav-tabs.control-sidebar-tabs > li.active > a:active {
    border-top: none;
    border-right: none;
    border-bottom: none;
}
@media (max-width: 768px) {
    .nav-tabs.control-sidebar-tabs {
        display: table;
    }
    .nav-tabs.control-sidebar-tabs > li {
        display: table-cell;
    }
}
.control-sidebar-heading {
    font-weight: 400;
    font-size: 16px;
    padding: 10px 0;
    margin-bottom: 10px;
}
.control-sidebar-subheading {
    display: block;
    font-weight: 400;
    font-size: 14px;
}
.control-sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0 -15px;
}
.control-sidebar-menu > li > a {
    display: block;
    padding: 10px 15px;
}
.control-sidebar-menu > li > a:before,
.control-sidebar-menu > li > a:after {
    content: " ";
    display: table;
}
.control-sidebar-menu > li > a:after {
    clear: both;
}
.control-sidebar-menu > li > a > .control-sidebar-subheading {
    margin-top: 0;
}
.control-sidebar-menu .menu-icon {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
}
.control-sidebar-menu .menu-info {
    margin-left: 45px;
    margin-top: 3px;
}
.control-sidebar-menu .menu-info > .control-sidebar-subheading {
    margin: 0;
}
.control-sidebar-menu .menu-info > p {
    margin: 0;
    font-size: 11px;
}
.control-sidebar-menu .progress {
    margin: 0;
}
.control-sidebar-dark {
    color: #b8c7ce;
}
.control-sidebar-dark,
.control-sidebar-dark + .control-sidebar-bg {
    background: #222d32;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs {
    border-bottom: #1c2529;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a {
    background: #181f23;
    color: #b8c7ce;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus {
    border-left-color: #141a1d;
    border-bottom-color: #141a1d;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:active {
    background: #1c2529;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover {
    color: $white100;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:active {
    background: #222d32;
    color: $white100;
}
.control-sidebar-dark .control-sidebar-heading,
.control-sidebar-dark .control-sidebar-subheading {
    color: $white100;
}
.control-sidebar-dark .control-sidebar-menu > li > a:hover {
    background: #1e282c;
}
.control-sidebar-dark .control-sidebar-menu > li > a .menu-info > p {
    color: #b8c7ce;
}
.control-sidebar-light {
    color: #5e5e5e;
}
.control-sidebar-light,
.control-sidebar-light + .control-sidebar-bg {
    background: #f9fafc;
    border-left: 1px solid #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs {
    border-bottom: #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a {
    background: #e8ecf4;
    color: #444;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus {
    border-left-color: #d2d6de;
    border-bottom-color: #d2d6de;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:active {
    background: #eff1f7;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:active {
    background: #f9fafc;
    color: #111;
}
.control-sidebar-light .control-sidebar-heading,
.control-sidebar-light .control-sidebar-subheading {
    color: #111;
}
.control-sidebar-light .control-sidebar-menu {
    margin-left: -14px;
}
.control-sidebar-light .control-sidebar-menu > li > a:hover {
    background: #f4f4f5;
}
.control-sidebar-light .control-sidebar-menu > li > a .menu-info > p {
    color: #5e5e5e;
}
.dropdown-menu {
    box-shadow: none;
    border-color: #eee;
}
.dropdown-menu > li > a {
    color: #777;
}
.dropdown-menu > li > a > .glyphicon,
.dropdown-menu > li > a > .fa,
.dropdown-menu > li > a > .ion {
    margin-right: 10px;
}
.dropdown-menu > li > a:hover {
    background-color: #e1e3e9;
    color: #333;
}
.dropdown-menu > .divider {
    background-color: #eee;
}
.navbar-nav > .notifications-menu > .dropdown-menu,
.navbar-nav > .messages-menu > .dropdown-menu,
.navbar-nav > .tasks-menu > .dropdown-menu {
    width: 280px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li,
.navbar-nav > .messages-menu > .dropdown-menu > li,
.navbar-nav > .tasks-menu > .dropdown-menu > li {
    position: relative;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.header,
.navbar-nav > .messages-menu > .dropdown-menu > li.header,
.navbar-nav > .tasks-menu > .dropdown-menu > li.header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;
    padding: 7px 10px;
    border-bottom: 1px solid #f4f4f4;
    color: #444444;
    font-size: 14px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 12px;
    background-color: #fff;
    padding: 7px 10px;
    border-bottom: 1px solid #eeeeee;
    color: #444 !important;
    text-align: center;
}
@media (max-width: 991px) {
    .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
    .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
    .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
        background: #fff !important;
        color: #444 !important;
    }
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a:hover {
    text-decoration: none;
    font-weight: normal;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu {
    max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #f4f4f4;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a:hover {
    background: #f4f4f4;
    text-decoration: none;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .glyphicon,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa,
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
    width: 20px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a {
    margin: 0;
    padding: 10px 10px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > div > img {
    margin: auto 10px auto auto;
    width: 40px;
    height: 40px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
    padding: 0;
    margin: 0 0 0 45px;
    color: #444444;
    font-size: 15px;
    position: relative;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
    color: #999999;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
    margin: 0 0 0 45px;
    font-size: 12px;
    color: #888888;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:before,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
    content: " ";
    display: table;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
    clear: both;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
    padding: 10px;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > h3 {
    font-size: 14px;
    padding: 0;
    margin: 0 0 10px 0;
    color: #666666;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > .progress {
    padding: 0;
    margin: 0;
}
.navbar-nav > .user-menu > .dropdown-menu {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 1px 0 0 0;
    border-top-width: 0;
    width: 280px;
}
.navbar-nav > .user-menu > .dropdown-menu,
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    font-size: 17px;
    margin-top: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
    padding: 15px;
    border-bottom: 1px solid #f4f4f4;
    border-top: 1px solid #dddddd;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
    content: " ";
    display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
    clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    color: #444 !important;
}
@media (max-width: 991px) {
    .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
        background: #fff !important;
        color: #444 !important;
    }
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer {
    background-color: #f9f9f9;
    padding: 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:before,
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
    content: " ";
    display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
    clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #666666;
}
@media (max-width: 991px) {
    .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f9f9f9;
    }
}
.navbar-nav > .user-menu .user-image {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
}
@media (max-width: 767px) {
    .navbar-nav > .user-menu .user-image {
        float: none;
        margin-right: 0;
        margin-top: -8px;
        line-height: 10px;
    }
}
.open:not(.dropup) > .animated-dropdown-menu {
    backface-visibility: visible !important;
    -webkit-animation: flipInX 0.7s both;
    -o-animation: flipInX 0.7s both;
    animation: flipInX 0.7s both;
}
@keyframes flipInX {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transition-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        transform: perspective(400px);
    }
}
@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-transition-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        -webkit-transform: perspective(400px);
    }
}
.navbar-custom-menu > .navbar-nav > li {
    position: relative;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
}
@media (max-width: 991px) {
    .navbar-custom-menu > .navbar-nav {
        float: right;
    }
    .navbar-custom-menu > .navbar-nav > li {
        position: static;
    }
    .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
        position: absolute;
        right: 5%;
        left: auto;
        border: 1px solid #ddd;
        background: #fff;
    }
} 
/*
.form-control:not(select) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-group.has-success label {
    color: #00a65a;
}
.form-group.has-success .form-control,
.form-group.has-success .input-group-addon {
    border-color: #00a65a;
    box-shadow: none;
}
.form-group.has-success .help-block {
    color: #00a65a;
}
.form-group.has-warning label {
    color: #f39c12;
}
.form-group.has-warning .form-control,
.form-group.has-warning .input-group-addon {
    border-color: #f39c12;
    box-shadow: none;
}
.form-group.has-warning .help-block {
    color: #f39c12;
}
.form-group.has-error label {
    color: #dd4b39;
}
.form-group.has-error .form-control,
.form-group.has-error .input-group-addon {
    border-color: #dd4b39;
    box-shadow: none;
}
.form-group.has-error .help-block {
    color: #dd4b39;
}
.input-group .input-group-addon {
    border-radius: 0;
    border-color: #d2d6de;
    background-color: #fff;
}
.btn-group-vertical .btn.btn-flat:first-of-type,
.btn-group-vertical .btn.btn-flat:last-of-type {
    border-radius: 0;
}
.icheck > label {
    padding-left: 0;
}
.form-control-feedback.fa {
    line-height: 34px;
}
.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
    line-height: 46px;
}
.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
    line-height: 30px;
}*/
.progress,
.progress > .progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress,
.progress > .progress-bar,
.progress .progress-bar,
.progress > .progress-bar .progress-bar {
    border-radius: 1px;
}
.progress.sm,
.progress-sm {
    height: 10px;
}
.progress.sm,
.progress-sm,
.progress.sm .progress-bar,
.progress-sm .progress-bar {
    border-radius: 1px;
}
.progress.xs,
.progress-xs {
    height: 7px;
}
.progress.xs,
.progress-xs,
.progress.xs .progress-bar,
.progress-xs .progress-bar {
    border-radius: 1px;
}
.progress.xxs,
.progress-xxs {
    height: 3px;
}
.progress.xxs,
.progress-xxs,
.progress.xxs .progress-bar,
.progress-xxs .progress-bar {
    border-radius: 1px;
}
.progress.vertical {
    position: relative;
    width: 30px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;
}
.progress.vertical > .progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}
.progress.vertical.sm,
.progress.vertical.progress-sm {
    width: 20px;
}
.progress.vertical.xs,
.progress.vertical.progress-xs {
    width: 10px;
}
.progress.vertical.xxs,
.progress.vertical.progress-xxs {
    width: 3px;
}
.progress-group .progress-text {
    font-weight: 600;
}
.progress-group .progress-number {
    float: right;
}
.table tr > td .progress {
    margin: 0;
}
.progress-bar-light-blue,
.progress-bar-primary {
    background-color: #3c8dbc;
}
.progress-striped .progress-bar-light-blue,
.progress-striped .progress-bar-primary {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-green,
.progress-bar-success {
    background-color: #00a65a;
}
.progress-striped .progress-bar-green,
.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-aqua,
.progress-bar-info {
    background-color: #00c0ef;
}
.progress-striped .progress-bar-aqua,
.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-yellow,
.progress-bar-warning {
    background-color: #f39c12;
}
.progress-striped .progress-bar-yellow,
.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-red,
.progress-bar-danger {
    background-color: #dd4b39;
}
.progress-striped .progress-bar-red,
.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.small-box > .inner {
    padding: 10px;
}
.small-box > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
}
.small-box > .small-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15);
}
.small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}
.small-box p {
    font-size: 15px;
}
.small-box p > small {
    display: block;
    color: #f9f9f9;
    font-size: 13px;
    margin-top: 5px;
}
.small-box h3,
.small-box p {
    z-index: 5;
}
.small-box .icon {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15);
}
.small-box:hover {
    text-decoration: none;
    color: #f9f9f9;
}
.small-box:hover .icon {
    font-size: 95px;
}
@media (max-width: 767px) {
    .small-box {
        text-align: center;
    }
    .small-box .icon {
        display: none;
    }
    .small-box p {
        font-size: 12px;
    }
}
.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box.box-primary {
    border-top-color: #3c8dbc;
}
.box.box-info {
    border-top-color: #00c0ef;
}
.box.box-danger {
    border-top-color: #dd4b39;
}
.box.box-warning {
    border-top-color: #f39c12;
}
.box.box-success {
    border-top-color: #00a65a;
}
.box.box-default {
    border-top-color: #d2d6de;
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
    display: none;
}
.box .nav-stacked > li {
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
}
.box .nav-stacked > li:last-of-type {
    border-bottom: none;
}
.box.height-control .box-body {
    max-height: 300px;
    overflow: auto;
}
.box .border-right {
    border-right: 1px solid #f4f4f4;
}
.box .border-left {
    border-left: 1px solid #f4f4f4;
}
.box.box-solid {
    border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
    background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
    background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
    border: 1px solid #d2d6de;
}
.box.box-solid.box-default > .box-header {
    color: #444;
    background: #d2d6de;
    background-color: #d2d6de;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
    color: #444;
}
.box.box-solid.box-primary {
    border: 1px solid #3c8dbc;
}
.box.box-solid.box-primary > .box-header {
    color: #fff;
    background: #3c8dbc;
    background-color: #3c8dbc;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
    color: #fff;
}
.box.box-solid.box-info {
    border: 1px solid #00c0ef;
}
.box.box-solid.box-info > .box-header {
    color: #fff;
    background: #00c0ef;
    background-color: #00c0ef;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
    color: #fff;
}
.box.box-solid.box-danger {
    border: 1px solid #dd4b39;
}
.box.box-solid.box-danger > .box-header {
    color: #fff;
    background: #dd4b39;
    background-color: #dd4b39;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
    color: #fff;
}
.box.box-solid.box-warning {
    border: 1px solid #f39c12;
}
.box.box-solid.box-warning > .box-header {
    color: #fff;
    background: #f39c12;
    background-color: #f39c12;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
    color: #fff;
}
.box.box-solid.box-success {
    border: 1px solid #00a65a;
}
.box.box-solid.box-success > .box-header {
    color: #fff;
    background: #00a65a;
    background-color: #00a65a;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
    color: #fff;
}
.box.box-solid > .box-header > .box-tools .btn {
    border: 0;
    box-shadow: none;
}
.box.box-solid[class*="bg"] > .box-header {
    color: #fff;
}
.box .box-group > .box {
    margin-bottom: 5px;
}
.box .knob-label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0.3em;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: $black100;
    font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
    content: " ";
    display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
    clear: both;
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.collapsed-box .box-header.with-border {
    border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
    margin-right: 5px;
}
.box-header > .box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
    position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
    right: 0;
    left: auto;
}
.box-header > .box-tools .dropdown-menu > li > a {
    color: #444 !important;
}
.btn-box-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #97a0b3;
}
.open .btn-box-tool,
.btn-box-tool:hover {
    color: #606c84;
}
.btn-box-tool.btn:active {
    box-shadow: none;
}
.box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
}
.no-header .box-body {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.box-body > .table {
    margin-bottom: 0;
}
.box-body .fc {
    margin-top: 5px;
}
.box-body .full-width-chart {
    margin: -19px;
}
.box-body.no-padding .full-width-chart {
    margin: -9px;
}
.box-body .box-pane {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
}
.box-body .box-pane-right {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
}
.box-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
}
.chart-legend {
    margin: 10px 0;
}
@media (max-width: 991px) {
    .chart-legend > li {
        float: left;
        margin-right: 10px;
    }
}
.box-comments {
    background: #f7f7f7;
}
.box-comments .box-comment {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}
.box-comments .box-comment:before,
.box-comments .box-comment:after {
    content: " ";
    display: table;
}
.box-comments .box-comment:after {
    clear: both;
}
.box-comments .box-comment:last-of-type {
    border-bottom: 0;
}
.box-comments .box-comment:first-of-type {
    padding-top: 0;
}
.box-comments .box-comment img {
    float: left;
}
.box-comments .comment-text {
    margin-left: 40px;
    color: #555;
}
.box-comments .username {
    color: #444;
    display: block;
    font-weight: 600;
}
.box-comments .text-muted {
    font-weight: 400;
    font-size: 12px;
}
.todo-list {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
}
.todo-list > li {
    border-radius: 2px;
    padding: 10px;
    background: #f4f4f4;
    margin-bottom: 2px;
    border-left: 2px solid #e6e7e8;
    color: #444;
}
.todo-list > li:last-of-type {
    margin-bottom: 0;
}
.todo-list > li > input[type="checkbox"] {
    margin: 0 10px 0 5px;
}
.todo-list > li .text {
    display: inline-block;
    margin-left: 5px;
    font-weight: 600;
}
.todo-list > li .label {
    margin-left: 10px;
    font-size: 9px;
}
.todo-list > li .tools {
    display: none;
    float: right;
    color: #dd4b39;
}
.todo-list > li .tools > .fa,
.todo-list > li .tools > .glyphicon,
.todo-list > li .tools > .ion {
    margin-right: 5px;
    cursor: pointer;
}
.todo-list > li:hover .tools {
    display: inline-block;
}
.todo-list > li.done {
    color: #999;
}
.todo-list > li.done .text {
    text-decoration: line-through;
    font-weight: 500;
}
.todo-list > li.done .label {
    background: #d2d6de !important;
}
.todo-list .danger {
    border-left-color: #dd4b39;
}
.todo-list .warning {
    border-left-color: #f39c12;
}
.todo-list .info {
    border-left-color: #00c0ef;
}
.todo-list .success {
    border-left-color: #00a65a;
}
.todo-list .primary {
    border-left-color: #3c8dbc;
}
.todo-list .handle {
    display: inline-block;
    cursor: move;
    margin: 0 5px;
}
.chat {
    padding: 5px 20px 5px 10px;
}
.chat .item {
    margin-bottom: 10px;
}
.chat .item:before,
.chat .item:after {
    content: " ";
    display: table;
}
.chat .item:after {
    clear: both;
}
.chat .item > img {
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-radius: 50%;
}
.chat .item > .online {
    border: 2px solid #00a65a;
}
.chat .item > .offline {
    border: 2px solid #dd4b39;
}
.chat .item > .message {
    margin-left: 55px;
    margin-top: -40px;
}
.chat .item > .message > .name {
    display: block;
    font-weight: 600;
}
.chat .item > .attachment {
    border-radius: 3px;
    background: #f4f4f4;
    margin-left: 65px;
    margin-right: 15px;
    padding: 10px;
}
.chat .item > .attachment > h4 {
    margin: 0 0 5px 0;
    font-weight: 600;
    font-size: 14px;
}
.chat .item > .attachment > p,
.chat .item > .attachment > .filename {
    font-weight: 600;
    font-size: 13px;
    font-style: italic;
    margin: 0;
}
.chat .item > .attachment:before,
.chat .item > .attachment:after {
    content: " ";
    display: table;
}
.chat .item > .attachment:after {
    clear: both;
}
.box-input {
    max-width: 200px;
}
.modal .panel-body {
    color: #444;
}
.info-box {
    display: block;
    min-height: 90px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 15px;
}
.info-box small {
    font-size: 14px;
}
.info-box .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 5px -10px 5px -10px;
    height: 2px;
}
.info-box .progress,
.info-box .progress .progress-bar {
    border-radius: 0;
}
.info-box .progress .progress-bar {
    background: #fff;
}
.info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0, 0, 0, 0.2);
}
.info-box-icon > img {
    max-width: 100%;
}
.info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
}
.info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}
.progress-description,
.info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-box-text {
    text-transform: uppercase;
}
.info-box-more {
    display: block;
}
.progress-description {
    margin: 0;
}
.timeline {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
}
.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 31px;
    margin: 0;
    border-radius: 2px;
}
.timeline > li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
}
.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}
.timeline > li:after {
    clear: both;
}
.timeline > li > .timeline-item {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 0;
    background: #fff;
    color: #444;
    margin-left: 60px;
    margin-right: 15px;
    padding: 0;
    position: relative;
}
.timeline > li > .timeline-item > .time {
    color: #999;
    float: right;
    padding: 10px;
    font-size: 12px;
}
.timeline > li > .timeline-item > .timeline-header {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 16px;
    line-height: 1.1;
}
.timeline > li > .timeline-item > .timeline-header > a {
    font-weight: 600;
}
.timeline > li > .timeline-item > .timeline-body,
.timeline > li > .timeline-item > .timeline-footer {
    padding: 10px;
}
.timeline > li > .fa,
.timeline > li > .glyphicon,
.timeline > li > .ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #666;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0;
}
.timeline > .time-label > span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    border-radius: 4px;
}
.timeline-inverse > li > .timeline-item {
    background: #f0f0f0;
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.timeline-inverse > li > .timeline-item > .timeline-header {
    border-bottom-color: #ddd;
}
/*
.btn {
    min-width: 150px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;  
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}
.btn-brand, .btn-brand:hover, .btn-brand:focus {  
   height: 40px;
   border: 1px solid transparent; 
   background-image: linear-gradient(to right, #459b90, #62a259);
   color: #fff;
}
.btn-brand-reverse {
   height: 40px;
   background: #fff !important;
   color: #1c1c1c !important;
   border: 1px solid rgba(104, 165, 87, 0.8) !important;
   box-shadow: none !important;
}
.form-control:disabled {
   background-color: #F3F5F6 !important;
   border: 1px solid #D4D8DB !important;
   color: #4A746F !important;
}
.btn.uppercase {
    text-transform: uppercase;
}
.btn.btn-flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-width: 1px;
}
.btn:active {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:focus {
    outline: none;
}
.btn.btn-file {
    position: relative;
    overflow: hidden;
}
.btn.btn-file > input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    opacity: 0;
    filter: alpha(opacity=0);
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
.btn-default {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
    background-color: #e7e7e7;
}
.btn-primary {
    background-color: #3c8dbc;
    border-color: #367fa9;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
    background-color: #367fa9;
}
.btn-success {
    background-color: #00a65a;
    border-color: #008d4c;
}
.btn-success:hover,
.btn-success:active,
.btn-success.hover {
    background-color: #008d4c;
}
.btn-info {
    background-color: #00c0ef;
    border-color: #00acd6;
}
.btn-info:hover,
.btn-info:active,
.btn-info.hover {
    background-color: #00acd6;
}
.btn-danger {
    background-color: #dd4b39;
    border-color: #d73925;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.hover {
    background-color: #d73925;
}
.btn-warning {
    background-color: #f39c12;
    border-color: #e08e0b;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.hover {
    background-color: #e08e0b;
}
.btn-outline {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7);
}
.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn[class*="bg-"]:hover {
    -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}
.btn-app {
    border-radius: 3px;
    position: relative;
    padding: 15px 5px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    height: 60px;
    text-align: center;
    color: #666;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    font-size: 12px;
}
.btn-app > .fa,
.btn-app > .glyphicon,
.btn-app > .ion {
    font-size: 20px;
    display: block;
}
.btn-app:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa;
}
.btn-app:active,
.btn-app:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-app > .badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400;
}*/
.callout {
    border-radius: 3px;
    margin: 0 0 20px 0;
    padding: 15px 30px 15px 15px;
    border-left: 5px solid #eee;
}
.callout a {
    color: #fff;
    text-decoration: underline;
}
.callout a:hover {
    color: #eee;
}
.callout h4 {
    margin-top: 0;
    font-weight: 600;
}
.callout p:last-child {
    margin-bottom: 0;
}
.callout code,
.callout .highlight {
    background-color: #fff;
}
.callout.callout-danger {
    border-color: #c23321;
}
.callout.callout-warning {
    border-color: #c87f0a;
}
.callout.callout-info {
    border-color: #0097bc;
}
.callout.callout-success {
    border-color: #00733e;
}
/*
.alert {
    border-radius: 3px;
}
.alert h4 {
    font-weight: 600;
}
.alert .icon {
    margin-right: 10px;
}
.alert .close {
    color: $black100;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.alert .close:hover {
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.alert a {
    color: #fff;
    text-decoration: underline;
}
.alert-success {
    border-color: #008d4c;
}
.alert-danger,
.alert-error {
    border-color: #d73925;
}
.alert-warning {
    border-color: #e08e0b;
}
.alert-info {
    border-color: #00acd6;
}*/
.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
    color: #444;
    background: #f7f7f7;
}
.nav-pills > li > a {
    border-radius: 0;
    border-top: 3px solid transparent;
    color: #444;
}
.nav-pills > li > a > .fa,
.nav-pills > li > a > .glyphicon,
.nav-pills > li > a > .ion {
    margin-right: 5px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    border-top-color: #3c8dbc;
}
.nav-pills > li.active > a {
    font-weight: 600;
}
.nav-stacked > li > a {
    border-radius: 0;
    border-top: 0;
    border-left: 3px solid transparent;
    color: #444;
}
.nav-stacked > li.active > a,
.nav-stacked > li.active > a:hover {
    background: transparent;
    color: #444;
    border-top: 0;
    border-left-color: #3c8dbc;
}
.nav-stacked > li.header {
    border-bottom: 1px solid #ddd;
    color: #777;
    margin-bottom: 10px;
    padding: 5px 10px;
    text-transform: uppercase;
}
.nav-tabs-custom {
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
.nav-tabs-custom > .nav-tabs {
    margin: 0;
    border-bottom-color: #f4f4f4;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.nav-tabs-custom > .nav-tabs > li {
    border-top: 3px solid transparent;
    margin-bottom: -2px;
    margin-right: 5px;
}
.nav-tabs-custom > .nav-tabs > li.disabled > a {
    color: #777;
}
.nav-tabs-custom > .nav-tabs > li > a {
    color: #444;
    border-radius: 0;
}
.nav-tabs-custom > .nav-tabs > li > a.text-muted {
    color: #999;
}
.nav-tabs-custom > .nav-tabs > li > a,
.nav-tabs-custom > .nav-tabs > li > a:hover {
    background: transparent;
    margin: 0;
}
.nav-tabs-custom > .nav-tabs > li > a:hover {
    color: #999;
}
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:hover,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:focus,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:active {
    border-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: #3c8dbc;
}
.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
    background-color: #fff;
    color: #444;
}
.nav-tabs-custom > .nav-tabs > li.active > a {
    border-top-color: transparent;
    border-left-color: #f4f4f4;
    border-right-color: #f4f4f4;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type {
    margin-left: 0;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type.active > a {
    border-left-color: transparent;
}
.nav-tabs-custom > .nav-tabs.pull-right {
    float: none !important;
}
.nav-tabs-custom > .nav-tabs.pull-right > li {
    float: right;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type {
    margin-right: 0;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type > a {
    border-left-width: 1px;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type.active > a {
    border-left-color: #f4f4f4;
    border-right-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.header {
    line-height: 35px;
    padding: 0 10px;
    font-size: 20px;
    color: #444;
}
.nav-tabs-custom > .nav-tabs > li.header > .fa,
.nav-tabs-custom > .nav-tabs > li.header > .glyphicon,
.nav-tabs-custom > .nav-tabs > li.header > .ion {
    margin-right: 5px;
}
.nav-tabs-custom > .tab-content {
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.nav-tabs-custom .dropdown.open > a:active,
.nav-tabs-custom .dropdown.open > a:focus {
    background: transparent;
    color: #999;
}
.nav-tabs-custom.tab-primary > .nav-tabs > li.active {
    border-top-color: #3c8dbc;
}
.nav-tabs-custom.tab-info > .nav-tabs > li.active {
    border-top-color: #00c0ef;
}
.nav-tabs-custom.tab-danger > .nav-tabs > li.active {
    border-top-color: #dd4b39;
}
.nav-tabs-custom.tab-warning > .nav-tabs > li.active {
    border-top-color: #f39c12;
}
.nav-tabs-custom.tab-success > .nav-tabs > li.active {
    border-top-color: #00a65a;
}
.nav-tabs-custom.tab-default > .nav-tabs > li.active {
    border-top-color: #d2d6de;
}
.pagination > li > a {
    background: #fafafa;
    color: #666;
}
.pagination.pagination-flat > li > a {
    border-radius: 0 !important;
}
.products-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.products-list > .item {
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    background: #fff;
}
.products-list > .item:before,
.products-list > .item:after {
    content: " ";
    display: table;
}
.products-list > .item:after {
    clear: both;
}
.products-list .product-img {
    float: left;
}
.products-list .product-img img {
    width: 50px;
    height: 50px;
}
.products-list .product-info {
    margin-left: 60px;
}
.products-list .product-title {
    font-weight: 600;
}
.products-list .product-description {
    display: block;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.product-list-in-box > .item {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #f4f4f4;
}
.product-list-in-box > .item:last-of-type {
    border-bottom-width: 0;
}
/*
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top: 1px solid #f4f4f4;
}
.table > thead > tr > th {
    border-bottom: 2px solid #f4f4f4;
}
.table tr td .progress {
    margin-top: 5px;
}
.table-bordered {
    border: 1px solid #f4f4f4;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #f4f4f4;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}
.table.no-border,
.table.no-border td,
.table.no-border th {
    border: 0;
}
table.text-center,
table.text-center td,
table.text-center th {
    text-align: center;
}
.table.align th {
    text-align: left;
}
.table.align td {
    text-align: right;
}
.label-default {
    background-color: #d2d6de;
    color: #444;
}*/
.direct-chat .box-body {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    overflow-x: hidden;
    padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
.direct-chat-messages {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 10px;
    height: 250px;
    overflow: auto;
}
.direct-chat-msg,
.direct-chat-text {
    display: block;
}
.direct-chat-msg {
    margin-bottom: 10px;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
    content: " ";
    display: table;
}
.direct-chat-msg:after {
    clear: both;
}
.direct-chat-messages,
.direct-chat-contacts {
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -moz-transition: -moz-transform 0.5s ease-in-out;
    -o-transition: -o-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
}
.direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 5px 10px;
    background: #d2d6de;
    border: 1px solid #d2d6de;
    margin: 5px 0 0 50px;
    color: #444;
}
.direct-chat-text:after,
.direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #d2d6de;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
}
.direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
}
.direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
}
.right .direct-chat-text {
    margin-right: 50px;
    margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: #d2d6de;
}
.direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 40px;
    height: 40px;
}
.right .direct-chat-img {
    float: right;
}
.direct-chat-info {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
}
.direct-chat-name {
    font-weight: 600;
}
.direct-chat-timestamp {
    color: #999;
}
.direct-chat-contacts-open .direct-chat-contacts {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
.direct-chat-contacts {
    -webkit-transform: translate(101%, 0);
    -ms-transform: translate(101%, 0);
    -o-transform: translate(101%, 0);
    transform: translate(101%, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    height: 250px;
    width: 100%;
    background: #222d32;
    color: #fff;
    overflow: auto;
}
.contacts-list > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 0;
}
.contacts-list > li:before,
.contacts-list > li:after {
    content: " ";
    display: table;
}
.contacts-list > li:after {
    clear: both;
}
.contacts-list > li:last-of-type {
    border-bottom: none;
}
.contacts-list-img {
    border-radius: 50%;
    width: 40px;
    float: left;
}
.contacts-list-info {
    margin-left: 45px;
    color: #fff;
}
.contacts-list-name,
.contacts-list-status {
    display: block;
}
.contacts-list-name {
    font-weight: 600;
}
.contacts-list-status {
    font-size: 12px;
}
.contacts-list-date {
    color: #aaa;
    font-weight: normal;
}
.contacts-list-msg {
    color: #999;
}
.direct-chat-danger .right > .direct-chat-text {
    background: #dd4b39;
    border-color: #dd4b39;
    color: #fff;
}
.direct-chat-danger .right > .direct-chat-text:after,
.direct-chat-danger .right > .direct-chat-text:before {
    border-left-color: #dd4b39;
}
.direct-chat-primary .right > .direct-chat-text {
    background: #3c8dbc;
    border-color: #3c8dbc;
    color: #fff;
}
.direct-chat-primary .right > .direct-chat-text:after,
.direct-chat-primary .right > .direct-chat-text:before {
    border-left-color: #3c8dbc;
}
.direct-chat-warning .right > .direct-chat-text {
    background: #f39c12;
    border-color: #f39c12;
    color: #fff;
}
.direct-chat-warning .right > .direct-chat-text:after,
.direct-chat-warning .right > .direct-chat-text:before {
    border-left-color: #f39c12;
}
.direct-chat-info .right > .direct-chat-text {
    background: #00c0ef;
    border-color: #00c0ef;
    color: #fff;
}
.direct-chat-info .right > .direct-chat-text:after,
.direct-chat-info .right > .direct-chat-text:before {
    border-left-color: #00c0ef;
}
.direct-chat-success .right > .direct-chat-text {
    background: #00a65a;
    border-color: #00a65a;
    color: #fff;
}
.direct-chat-success .right > .direct-chat-text:after,
.direct-chat-success .right > .direct-chat-text:before {
    border-left-color: #00a65a;
}
.users-list > li {
    width: 25%;
    float: left;
    padding: 10px;
    text-align: center;
}
.users-list > li img {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}
.users-list > li > a:hover,
.users-list > li > a:hover .users-list-name {
    color: #999;
}
.users-list-name,
.users-list-date {
    display: block;
}
.users-list-name {
    font-weight: 600;
    color: #444;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.users-list-date {
    color: #999;
    font-size: 12px;
}
.carousel-control.left,
.carousel-control.right {
    background-image: none;
}
.carousel-control > .fa {
    font-size: 40px;
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -20px;
}
.box-widget {
    border: none;
    position: relative;
}
.widget-user .widget-user-header {
    padding: 20px;
    height: 120px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.widget-user .widget-user-username {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.widget-user .widget-user-desc {
    margin-top: 0;
}
.widget-user .widget-user-image {
    position: absolute;
    top: 65px;
    left: 50%;
    margin-left: -45px;
}
.widget-user .widget-user-image > img {
    width: 90px;
    height: auto;
    border: 3px solid #fff;
}
.widget-user .box-footer {
    padding-top: 30px;
}
.widget-user-2 .widget-user-header {
    padding: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.widget-user-2 .widget-user-username {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
}
.widget-user-2 .widget-user-desc {
    margin-top: 0;
}
.widget-user-2 .widget-user-username,
.widget-user-2 .widget-user-desc {
    margin-left: 75px;
}
.widget-user-2 .widget-user-image > img {
    width: 65px;
    height: auto;
    float: left;
}
.treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 5px;
}
.treeview-menu .treeview-menu {
    padding-left: 20px;
}
.treeview-menu > li {
    margin: 0;
}
.treeview-menu > li > a {
    padding: 5px 5px 5px 15px;
    display: block;
    font-size: 14px;
}
.treeview-menu > li > a > .fa,
.treeview-menu > li > a > .glyphicon,
.treeview-menu > li > a > .ion {
    width: 20px;
}
.treeview-menu > li > a > .pull-right-container > .fa-angle-left,
.treeview-menu > li > a > .pull-right-container > .fa-angle-down,
.treeview-menu > li > a > .fa-angle-left,
.treeview-menu > li > a > .fa-angle-down {
    width: auto;
}
.mailbox-messages > .table {
    margin: 0;
}
.mailbox-controls {
    padding: 5px;
}
.mailbox-controls.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.mailbox-read-info {
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
}
.mailbox-read-info h3 {
    font-size: 20px;
    margin: 0;
}
.mailbox-read-info h5 {
    margin: 0;
    padding: 5px 0 0 0;
}
.mailbox-read-time {
    color: #999;
    font-size: 13px;
}
.mailbox-read-message {
    padding: 10px;
}
.mailbox-attachments li {
    float: left;
    width: 200px;
    border: 1px solid #eee;
    margin-bottom: 10px;
    margin-right: 10px;
}
.mailbox-attachment-name {
    font-weight: bold;
    color: #666;
}
.mailbox-attachment-icon,
.mailbox-attachment-info,
.mailbox-attachment-size {
    display: block;
}
.mailbox-attachment-info {
    padding: 10px;
    background: #f4f4f4;
}
.mailbox-attachment-size {
    color: #999;
    font-size: 12px;
}
.mailbox-attachment-icon {
    text-align: center;
    font-size: 65px;
    color: #666;
    padding: 20px 10px;
}
.mailbox-attachment-icon.has-img {
    padding: 0;
}
.mailbox-attachment-icon.has-img > img {
    max-width: 100%;
    height: auto;
}
.lockscreen {
    background: #d2d6de;
}
.lockscreen-logo {
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 300;
}
.lockscreen-logo a {
    color: #444;
}
.lockscreen-wrapper {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 10%;
}
.lockscreen .lockscreen-name {
    text-align: center;
    font-weight: 600;
}

.primary-box-gradient{
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 0;
    min-height: 109px;
    &.clients-box {
        p {
            font-size: 12px;
        }
    }
    p {
        font-size: 12px;
       
    }
    h2 {
        font-size: 16px !important;
        color: $black100;
    }
    h4 {
        font-size: 14px !important;
        color: $black100;
    }
    h5 {
        font-size: 14px;
        color: $black100;
    }
    h6 {
        font-size: 12px !important;
        color: $black100;
    }
}